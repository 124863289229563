export const commentTags = [
  '500',
  '501',
  '502',
  '504',
  '505',
  '506',
  '507',
  '508',
  '510',
  '511',
  '513',
  '514',
  '515',
  '516',
  '518',
  '520',
  '521',
  '522',
  '524',
  '525',
  '526',
  '530',
  '533',
  '534',
  '535',
  '536',
  '538',
  '540',
  '541',
  '542',
  '544',
  '545',
  '546',
  '550',
  '552',
  '555',
  '556',
  '561',
  '562',
  '563',
  '565',
  '567',
  '580',
  '581',
  '583',
  '584',
  '585',
  '586',
  '588',
  '599',
];
