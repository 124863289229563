import React from 'react';
import { Link } from 'gatsby';
import { getValueFromMarc, getValuesFromMarc } from '../utils/marc';
import { useBibSearchResultStateSetters } from './useBibSearchResultStateSetters';
import { commentTags } from '../constants/commentTags';

export const useDocumentDetailsValues = () => {
  const {
    setBibSearchResultStateProperties,
  } = useBibSearchResultStateSetters();

  /**
   * Joins multiple values to be displayed.
   *
   * @param {[{ value: string; separator: string; decorator: any }]} config
   * @param {import('@material-ui/core').GridProps} containerProps
   */
  const joinValues = config => {
    const joinedValues = config
      ?.filter(item => item.value)
      ?.map((item, index) => {
        const { value, separator = '', decorator = value => value } = item;
        return (
          <>
            {index > 0 ? separator : ''}
            {decorator(value)}
          </>
        );
      });

    return joinedValues?.length > 0 ? <>{joinedValues}</> : null;
  };

  /**
   * Resolves a unit of values array and returns it as a string.
   */
  const resolveUnitOfValues = unitOfValues =>
    unitOfValues?.map(unitOfValue => unitOfValue.literalValue)?.join('');

  const searchLink = '/record_list';

  /**
   * Generates link props that navigate to the record list search.
   *
   * @param {string} slug The search slug.
   * @param {string} title The title of the search to be displayed.
   * @param {string} value The value to search.
   * @returns {import('@material-ui/core').LinkProps}
   */
  const generateSearchLinkProps = (slug, title, value) => ({
    to: searchLink,
    state: {
      searchField: { slug, title },
      searchValue: { value },
    },
    onClick: () =>
      setBibSearchResultStateProperties({
        page: 0,
        filters: {},
        aggregations: {},
      }),
  });

  const getTitleValue = rawMarcRecord => {
    const config = [
      {
        value: getValueFromMarc('245', 'a', rawMarcRecord),
        separator: ' : ',
      },
      {
        value: getValueFromMarc('245', 'b', rawMarcRecord),
        separator: ' : ',
      },
      {
        value: getValueFromMarc('245', 'c', rawMarcRecord),
        separator: ' / ',
      },
    ];

    const joinedValues = joinValues(config);
    return joinedValues;
  };

  const getNamesValue = rawMarcRecord => {
    const values = [
      getValueFromMarc('100', 'a', rawMarcRecord),
      getValueFromMarc('700', 'a', rawMarcRecord),
      getValueFromMarc('710', 'a', rawMarcRecord),
      getValueFromMarc('650', 'a', rawMarcRecord),
    ];

    const decorator = value => (
      <Link
        {...generateSearchLinkProps('author', 'Szerző', value)}
        style={{ display: 'block' }}
      >
        {value}
      </Link>
    );

    const config = values.map(value => ({
      value,
      decorator,
    }));

    const joinedValues = joinValues(config);

    return joinedValues;
  };

  const getDocumentTypeValue = rawMarcRecord =>
    getValueFromMarc('901', 'a', rawMarcRecord);

  const getSubtypeFormatValue = rawMarcRecord =>
    getValueFromMarc('901', 'b', rawMarcRecord) ||
    getValueFromMarc('245', 'h', rawMarcRecord);

  const getBaseDocumentValue = rawMarcRecord =>
    resolveUnitOfValues(
      getValueFromMarc('773', 't', rawMarcRecord, 'unitOfValues')
    );

  const getLanguageValue = rawMarcRecord =>
    getValueFromMarc('041', 'a', rawMarcRecord);

  const getIsbnValue = rawMarcRecord =>
    getValueFromMarc('020', 'a', rawMarcRecord);

  const getIssnValue = rawMarcRecord =>
    getValueFromMarc('022', 'a', rawMarcRecord);

  const getStockDataValue = rawMarcRecord =>
    getValueFromMarc('362', 'a', rawMarcRecord);

  const getFrequencyValue = rawMarcRecord =>
    getValueFromMarc('310', 'a', rawMarcRecord);

  const getClassificationMarksValue = rawMarcRecord => {
    const values = getValuesFromMarc('084', 'a', rawMarcRecord);
    const config = values?.map(value => ({
      value,
      separator: ', ',
      decorator: value => (
        <Link {...generateSearchLinkProps('keyword', 'Kulcsszó', value)}>
          {value}
        </Link>
      ),
    }));

    const joinedValues = joinValues(config);
    return joinedValues;
  };

  const getReleaseSignalValue = rawMarcRecord =>
    getValueFromMarc('250', 'a', rawMarcRecord);

  const getFullReleaseValue = rawMarcRecord => {
    const config = [
      {
        value: getValueFromMarc('260', 'a', rawMarcRecord),
        separator: ' : ',
      },
      {
        value: getValueFromMarc('260', 'b', rawMarcRecord),
        separator: ' : ',
        decorator: value => (
          <Link {...generateSearchLinkProps('publisher', 'Kiadó', value)}>
            {value}
          </Link>
        ),
      },
      {
        value: getValueFromMarc('260', 'c', rawMarcRecord),
        separator: ', ',
      },
    ];

    const joinedValues = joinValues(config);
    return joinedValues;
  };

  const getPartialReleaseValue = rawMarcRecord =>
    getValueFromMarc('260', 'c', rawMarcRecord);

  const getFullSizeDataValue = rawMarcRecord => {
    const values = [
      getValueFromMarc('300', 'a', rawMarcRecord),
      getValueFromMarc('300', 'b', rawMarcRecord),
      getValueFromMarc('300', 'c', rawMarcRecord),
      getValueFromMarc('300', 'e', rawMarcRecord),
    ];

    const separator = '; ';
    const config = values.map(value => ({
      value,
      separator,
    }));

    const joinedValues = joinValues(config);
    return joinedValues;
  };

  const getPartialSizeDataValue = rawMarcRecord =>
    getValueFromMarc('300', 'a', rawMarcRecord);

  const getTemporalPublicationSizeDataValue = rawMarcRecord => {
    const values = [
      getValueFromMarc('300', 'b', rawMarcRecord),
      getValueFromMarc('300', 'c', rawMarcRecord),
    ];

    const separator = '; ';
    const config = values.map(value => ({
      value,
      separator,
    }));

    const joinedValues = joinValues(config);
    return joinedValues;
  };

  const getSeriesValue = rawMarcRecord => {
    const valueA = getValueFromMarc('490', 'a', rawMarcRecord);
    const valueV = getValueFromMarc('490', 'v', rawMarcRecord);
    const valueX = getValueFromMarc('490', 'x', rawMarcRecord);

    const value = [valueA, valueV, valueX].filter(x => x).join(', ');
    return value ? (
      <Link {...generateSearchLinkProps('serie', 'Sorozat', value)}>
        {value}
      </Link>
    ) : (
      value
    );
  };

  const getCommentsValue = rawMarcRecord => {
    const separator = ', ';
    const config = commentTags.map(tag => ({
      value: getValueFromMarc(tag, 'a', rawMarcRecord),
      separator,
    }));

    const joinedValues = joinValues(config);
    return joinedValues;
  };

  const getSubjectWordsValue = rawMarcRecord => {
    const values = [
      ...(getValuesFromMarc('650', 'a', rawMarcRecord) ?? []),
      // ...(getValuesFromMarc('651', 'a', rawMarcRecord) ?? []),
      ...(getValuesFromMarc('653', 'a', rawMarcRecord) ?? []),
    ];

    const separator = ', ';
    const decorator = value => (
      <Link {...generateSearchLinkProps('subject', 'Tárgyszó', value)}>
        {value}
      </Link>
    );

    const config = values.map(value => ({
      value,
      separator,
      decorator,
    }));

    const joinedValues = joinValues(config);
    return joinedValues;
  };

  const getOtherTitlesValue = rawMarcRecord =>
    getValueFromMarc('245', 'b', rawMarcRecord);

  const getRelatedTitlesValue = rawMarcRecord => {
    const separator = '; ';
    const config = [
      {
        value: resolveUnitOfValues(
          getValueFromMarc('780', 't', rawMarcRecord, 'unitOfValues')
        ),
        separator,
      },
      {
        value: resolveUnitOfValues(
          getValueFromMarc('785', 't', rawMarcRecord, 'unitOfValues')
        ),
        separator,
      },
    ];

    const joinedValues = joinValues(config);
    return joinedValues;
  };

  const getOnlineAvailabilityValue = rawMarcRecord => {
    const value = getValueFromMarc('856', 'u', rawMarcRecord);
    return value ? <Link to={value}>{value}</Link> : value;
  };

  const getDefaultDocumentDetailsValues = rawMarcRecord => [
    {
      name: 'Cím',
      value: getTitleValue(rawMarcRecord),
    },
    {
      name: 'Nevek',
      value: getNamesValue(rawMarcRecord),
    },
    {
      name: 'Dokumentumtípus',
      value: getDocumentTypeValue(rawMarcRecord),
    },
    {
      name: 'Altípus/formátum',
      value: getSubtypeFormatValue(rawMarcRecord),
    },
    {
      name: 'Nyelv',
      value: getLanguageValue(rawMarcRecord),
    },
    {
      name: 'ISBN',
      value: getIsbnValue(rawMarcRecord),
    },
    {
      name: 'Osztályozási jelzetek',
      value: getClassificationMarksValue(rawMarcRecord),
    },
    {
      name: 'Kiadásjelzés',
      value: getReleaseSignalValue(rawMarcRecord),
    },
    {
      name: 'Megjelenés',
      value: getFullReleaseValue(rawMarcRecord),
    },
    {
      name: 'Méretadatok',
      value: getFullSizeDataValue(rawMarcRecord),
    },
    {
      name: 'Sorozat',
      value: getSeriesValue(rawMarcRecord),
    },
    {
      name: 'Megjegyzések',
      value: getCommentsValue(rawMarcRecord),
    },
    {
      name: 'Tárgyszavak',
      value: getSubjectWordsValue(rawMarcRecord),
    },
    {
      name: 'Egyéb címek',
      value: getOtherTitlesValue(rawMarcRecord),
    },
  ];

  const getAnalyticsDetailsValues = rawMarcRecord => [
    {
      name: 'Cikk címe',
      value: getTitleValue(rawMarcRecord),
    },
    {
      name: 'Nevek',
      value: getNamesValue(rawMarcRecord),
    },
    {
      name: 'Dokumentumtípus',
      value: getDocumentTypeValue(rawMarcRecord),
    },
    {
      name: 'Altípus/formátum',
      value: getSubtypeFormatValue(rawMarcRecord),
    },
    {
      name: 'Alapdokumentum',
      value: getBaseDocumentValue(rawMarcRecord),
    },
    {
      name: 'Nyelv',
      value: getLanguageValue(rawMarcRecord),
    },
    {
      name: 'Osztályozási jelzetek',
      value: getClassificationMarksValue(rawMarcRecord),
    },
    {
      name: 'Megjelenés',
      value: getPartialReleaseValue(rawMarcRecord),
    },
    {
      name: 'Méretadatok',
      value: getPartialSizeDataValue(rawMarcRecord),
    },
    {
      name: 'Megjegyzések',
      value: getCommentsValue(rawMarcRecord),
    },
    {
      name: 'Tárgyszavak',
      value: getSubjectWordsValue(rawMarcRecord),
    },
  ];

  const getVolumeDetailsValues = rawMarcRecord => [
    {
      name: 'Cím',
      value: getTitleValue(rawMarcRecord),
    },
    {
      name: 'Nevek',
      value: getNamesValue(rawMarcRecord),
    },
    {
      name: 'Dokumentumtípus',
      value: getDocumentTypeValue(rawMarcRecord),
    },
    {
      name: 'Altípus/formátum',
      value: getSubtypeFormatValue(rawMarcRecord),
    },
    {
      name: 'Alapdokumentum',
      value: getBaseDocumentValue(rawMarcRecord),
    },
    {
      name: 'ISBN',
      value: getIsbnValue(rawMarcRecord),
    },
    {
      name: 'Osztályozási jelzetek',
      value: getClassificationMarksValue(rawMarcRecord),
    },
    {
      name: 'Megjelenés',
      value: getPartialReleaseValue(rawMarcRecord),
    },
    {
      name: 'Méretadatok',
      value: getPartialSizeDataValue(rawMarcRecord),
    },
    {
      name: 'Sorozat',
      value: getSeriesValue(rawMarcRecord),
    },
    {
      name: 'Megjegyzések',
      value: getCommentsValue(rawMarcRecord),
    },
    {
      name: 'Tárgyszavak',
      value: getSubjectWordsValue(rawMarcRecord),
    },
    {
      name: 'Egyéb címek',
      value: getOtherTitlesValue(rawMarcRecord),
    },
  ];

  const getTemporalPublicationDetailsValues = rawMarcRecord => [
    {
      name: 'Folyóirat címe',
      value: getTitleValue(rawMarcRecord),
    },
    {
      name: 'Nevek',
      value: getNamesValue(rawMarcRecord),
    },
    {
      name: 'Dokumentumtípus',
      value: getDocumentTypeValue(rawMarcRecord),
    },
    {
      name: 'Altípus/formátum',
      value: getSubtypeFormatValue(rawMarcRecord),
    },
    {
      name: 'Nyelv',
      value: getLanguageValue(rawMarcRecord),
    },
    {
      name: 'ISSN',
      value: getIssnValue(rawMarcRecord),
    },
    {
      name: 'Állományadatok',
      value: getStockDataValue(rawMarcRecord),
    },
    {
      name: 'Gyakoriság',
      value: getFrequencyValue(rawMarcRecord),
    },
    {
      name: 'Osztályozási jelzetek',
      value: getClassificationMarksValue(rawMarcRecord),
    },
    {
      name: 'Megjelenés',
      value: getFullReleaseValue(rawMarcRecord),
    },
    {
      name: 'Méretadatok',
      value: getTemporalPublicationSizeDataValue(rawMarcRecord),
    },
    {
      name: 'Sorozat',
      value: getSeriesValue(rawMarcRecord),
    },
    {
      name: 'Megjegyzések',
      value: getCommentsValue(rawMarcRecord),
    },
    {
      name: 'Egyéb címek',
      value: getOtherTitlesValue(rawMarcRecord),
    },
    {
      name: 'Kapcsolódó címek',
      value: getRelatedTitlesValue(rawMarcRecord),
    },
    {
      name: 'Tárgyszavak',
      value: getSubjectWordsValue(rawMarcRecord),
    },
    {
      name: 'Online elérhetőség',
      value: getOnlineAvailabilityValue(rawMarcRecord),
    },
  ];

  return {
    getDefaultDocumentDetailsValues,
    getAnalyticsDetailsValues,
    getVolumeDetailsValues,
    getTemporalPublicationDetailsValues,
  };
};
