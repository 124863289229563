import React from "react"
import { Router, Redirect } from "@reach/router"
import ElementTemplate from "../templates/element_template";
import NoContent from "./404";
import MultiPrint from "../components/ElementList/CheckedOperations/MultiPrint";

const Elements = (props) => {
    return (
        <Router>
            {/* <NoContent path='/elements' /> */}
            <ElementTemplate path='/elements/*' />
        </Router>
    )
}
export default Elements