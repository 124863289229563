import React, { useEffect, useState } from 'react';
import SubPageLayout from '../layouts/subpage_layout';
import BreadcrumbsLayout from '../layouts/breadcrumbs_layout';
import { useSnackbar } from 'notistack';
import {
  Box,
  Grid,
  Button,
  Snackbar,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import '../styles/basic_style.scss';
import styles from '../pages/index.module.scss';
import elementTemplateStyles from './element_template.module.scss';
import searchStyles from '../pages/search.module.scss';
import Head from '../components/head';
import ErrorSnackbar from '../components/ErrorSnackbar/ErrorSnackbar';
import Pagination from 'material-ui-flat-pagination';
// import RelatedRecords from '../components/RelatedRecords/RelatedRecords';
import gql from 'graphql-tag';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { useAuth } from '../hooks/useAuth';
import ItemsTable from '../components/ElementList/ElementListRecords/ItemsTable/ItemsTable';
import { ME_QUERY } from '../apollo/queries/searchRelatedQueries';
import ViewMyReservationModal from '../components/ElementList/ElementListRecords/ViewMyReservationModal/ViewMyReservationModal';
import ViewMyRequestModal from '../components/ElementList/ElementListRecords/ViewMyRequestModal/ViewMyRequestModal';
import { getBibQuery } from './query';
import { documentTypes } from '../constants/documentTypes';
import { getValueFromMarc } from '../utils/marc';
import { useDocumentDetailsValues } from '../hooks/useDocumentDetailsValues';
import RecordConnectionController from '../components/RecordConnectionController/RecordConnectionController';
import ConnectedRecords from '../components/ElementList/ElementListRecords/ConnectedRecords/ConnectedRecords';
import { connectedRecordTypeBasedOnPeriodicLevel } from '../constants/common';
import DocumentsTable from '../components/ElementList/ElementListRecords/DocumentsTable/DocumentsTable';
import { controlledFeatures } from '../utils/featureControl';

const GET_DOCTYPE_DATA_STATIC_QUERY = graphql`
  query getDocdataForElements {
    allDoctypeMappingCsv {
      nodes {
        hun
        key
        svg
        eng
      }
    }
  }
`;

const REQUEST_BIB_RECORD_MUTATION = gql`
  mutation($guid: UUID!) {
    requestRequestForBibliographicRecord(
      data: { bibliographicRecord: { guid: $guid } }
    ) {
      id
      requestDate
      expiryDate
    }
  }
`;

const ElementTemplate = props => {
  useEffect(() => {}, []);
  const { accessToken } = useAuth();
  const [errorModal, setErrorModal] = useState({
    open: false,
    errorMessage: null,
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });

  const closeErrorModalHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorModal({ ...errorModal, open: false });
  };

  const { data: meData } = useQuery(ME_QUERY, {
    skip: !accessToken,
  });

  const [reservationData, setReservationData] = useState(null);
  const [requestData, setRequestData] = useState(null);

  let fromElements = false;

  if (props.location.state) fromElements = true;

  let documentId = props.location.pathname[10];
  for (let i = 11; i < props.location.pathname.length; i++) {
    if (props.location.pathname[i] === '/') break;
    documentId += props.location.pathname[i];
  }

  const { enqueueSnackbar } = useSnackbar();

  const staticData = useStaticQuery(GET_DOCTYPE_DATA_STATIC_QUERY);
  const { allDoctypeMappingCsv } = staticData;

  const client = useApolloClient();

  const { data, loading, error, fetchMore } = useQuery(getBibQuery({fetchItems: controlledFeatures.itemList }), {
    variables: {
      id: documentId ? documentId : '',
      first: 10,
      skip: 0,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted() {
      if (props.location.pathname.slice(-6) === 'mailto') {
        window.location.href = `mailto:?subject=${data?.bibliographicRecord?.guid}&body=Kijelölt rekord:${mailData}`;
        navigate(-1);
      }
      if (props.location.pathname.slice(-5) === 'print') {
        setTimeout(printPage, 500);
      }
    },
  });

  const {
    getDefaultDocumentDetailsValues,
    getAnalyticsDetailsValues,
    getVolumeDetailsValues,
    getTemporalPublicationDetailsValues,
  } = useDocumentDetailsValues();

  const printPage = () => {
    window.print();
    navigate(-1);
  };

  const [requestBibRecord, { loading: mutationLoading }] = useMutation(
    REQUEST_BIB_RECORD_MUTATION,
    {
      onCompleted() {
        enqueueSnackbar('Sikeres előjegyzés!', {
          variant: 'success',
        });
      },
      onError(err) {
        enqueueSnackbar(`Sikertelen előjegyzés! Üzenet: ${error.message}`, {
          variant: 'error',
        });
      },
      update() {
        try {
          const updateVariables = {
            id: documentId,
            first: 10,
            skip: 0,
          };

          const readData = client.readQuery({
            query: getBibQuery({fetchItems: controlledFeatures.itemList }),
            variables: updateVariables,
          });

          client.writeQuery({
            query: getBibQuery({fetchItems: controlledFeatures.itemList }),
            variables: updateVariables,
            data: {
              ...readData,
              bibliographicRecord: {
                ...readData.bibliographicRecord,
                hasActiveRequest: true,
              },
            },
          });
        } catch (err) {}
      },
    }
  );

  const first = 10;
  const [skip, setSkip] = useState(0);

  //

  const typeIconDisplay = () => {
    const cover = data?.bibliographicRecord?.publicFiles?.find(
      file => file.classification === 'cover'
    );

    if (cover?.publicUrl) {
      return cover.publicUrl;
    }

    const matchDoctypeData = allDoctypeMappingCsv.nodes.find(
      node => node.hun === data.bibliographicRecord.type
    );
    if (!matchDoctypeData) {
      const matchElseStaticData = allDoctypeMappingCsv.nodes.find(
        node => node.key === 'else'
      );
      return matchElseStaticData.svg;
    }
    return matchDoctypeData.svg;
  };

  const parseArray = value => {
    if (Array.isArray(value)) {
      let output = '';
      if (value) {
        for (let i = 0; i < value.length; i++) {
          i === value.length - 1
            ? (output += value[i])
            : (output += value[i] + ' ');
        }
        return output;
      }
    }
    return value;
  };

  const handleBibRequest = async () => {
    if (mutationLoading) return;
    // const res = client.query({
    // })

    requestBibRecord({
      variables: {
        guid: documentId,
      },
    });
  };

  let pageDisplay = null;
  let pageTitle = 'Tartalom oldal';

  if (error) {
    pageTitle = 'Hiba a lekérdezés közben';
    // pageDisplay = (
    //   <div className={elementTemplateStyles.errorContainer}>
    //     <div>Hiba merült fel lekérdezés közben.</div>
    //     <div>Bővebb információ: {error.message}</div>
    //   </div>
    // )

    //SNACKBAR
    if (errorModal.errorMessage === null) {
      if (error.graphQLErrors[0]) {
        if (
          error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' &&
          error.message.includes('internal server error')
        ) {
          setErrorModal({
            open: true,
            errorMessage:
              'Az adatlap jelenleg nem elérhető vagy a dokumentum nem létezik az adatbázisban.',
          });
        } else if (
          error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' &&
          error.message.includes('Too many requests')
        ) {
          setErrorModal({
            open: true,
            errorMessage:
              'Ön elérte az egy percen belül indítható maximális bejelentkezési kísérlet számát, kérjük próbálkozzon újra egy perc múlva.',
          });
        }
      } else {
        setErrorModal({ open: true, errorMessage: 'Hálózati hiba' });
      }
    }
  }

  const resolveTypeFromCsv = rawType => {
    const find = allDoctypeMappingCsv.nodes.find(node => node.key === rawType);

    if (!find) return rawType;
    return find.hun;
  };

  const get901bValue = () => {
    const subfieldValue = data.bibliographicRecord.rawMarcRecord.datafields
      .find(field => field.tag === '901')
      ?.subfields?.find(subfield => subfield.code === 'b')?.value
      ?.resolvedValue;

    return (
      documentTypes.find(type => type.value === subfieldValue)?.displayName ||
      subfieldValue
    );
  };

  const br = '%0D%0A';
  const mailData = `${br}
    ${resolveTypeFromCsv(data?.bibliographicRecord?.type)}
    ${br}
    Cím: ${getValueFromMarc(
      '787',
      'g',
      data?.bibliographicRecord?.rawMarcRecord
    ) ||
      getValueFromMarc('774', 'g', data?.bibliographicRecord?.rawMarcRecord) ||
      getValueFromMarc('245', 'a', data?.bibliographicRecord?.rawMarcRecord) ||
      'nem ismert'}
    ${br}
    Szerző: ${getValueFromMarc(
      '100',
      'a',
      data?.bibliographicRecord?.rawMarcRecord
    ) ||
      getValueFromMarc('245', 'c', data?.bibliographicRecord?.rawMarcRecord) ||
      'nem ismert'}
    ${br}
    Kiadó: ${data?.bibliographicRecord?.publisher || 'nem ismert'}
    ${br}
    Link: ${(
      props.location.origin +
      `/elements/${data?.bibliographicRecord?.guid}/${data?.bibliographicRecord?.guid}`
    ).toString()}
    `;

  if (loading) {
    pageDisplay = (
      <center>
        <div className={elementTemplateStyles.ldsSpinner}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </center>
    );
  }

  if (data && !loading) {
    // history.replaceState({}, "", data.bibliographicRecord.originalId);
    // history.replaceState({}, "", documentId + '/' + data.bibliographicRecord.originalId);
    // window.history.pushState( {} , 'bar', documentId + '/' + data.bibliographicRecord.originalId );
    // history.replaceState(null, null, "bar2");

    const { bibliographicRecord } = data;
    const { guid, fullTitle, title, rawMarcRecord, physicalManifestations } =
      bibliographicRecord ?? {};
    pageTitle = title;
    const docType = getValueFromMarc('901', 'a', rawMarcRecord);

    const documentSummarizedValues = [
      {
        id: 'title',
        name: 'Cím',
        value:
          getValueFromMarc('245', 'a', rawMarcRecord) || fullTitle || title,
      },
      {
        name: 'Szerző',
        value:
          getValueFromMarc('100', 'a', rawMarcRecord) ||
          getValueFromMarc('245', 'c', rawMarcRecord),
      },
      {
        name: 'Kiadó',
        value: getValueFromMarc('260', 'b', rawMarcRecord),
      },
    ];

    let documentDetailsValues;
    switch (docType) {
      case 'Analitika':
        documentDetailsValues = getAnalyticsDetailsValues(rawMarcRecord);
        break;
      case 'Kötet':
        documentDetailsValues = getVolumeDetailsValues(rawMarcRecord);
        break;
      case 'Időszaki kiadvány':
        documentDetailsValues = getTemporalPublicationDetailsValues(
          rawMarcRecord
        );
        break;
      default:
        documentDetailsValues = getDefaultDocumentDetailsValues(rawMarcRecord);
        break;
    }

    const dataList = matches
      ? documentSummarizedValues.filter(x => x?.id !== 'title')
      : documentSummarizedValues;
    const titleData = documentSummarizedValues.find(x => x?.id === 'title');

    const documents =
      bibliographicRecord?.publicFiles?.filter(
        file => file.classification === 'service'
      ) ?? [];

    pageDisplay = (
      <React.Fragment>
        <Box className={'docDataMoreHeadline'}>
          <span>Összefoglalás</span>
        </Box>
        <div className={elementTemplateStyles.osszefoglalasContainer}>
          {matches ? (
            <Grid container spacing={2}>
              <Grid item>
                <div className={elementTemplateStyles.typeImgContainer}>
                  <Box className={elementTemplateStyles.typeImgContaincerBox}>
                    <img src={typeIconDisplay()} alt="dokumentum típus" />
                  </Box>
                </div>
              </Grid>
              <Grid item>{titleData?.value}</Grid>
            </Grid>
          ) : (
            <div className={elementTemplateStyles.typeImgContainer}>
              <Box className={elementTemplateStyles.typeImgContaincerBox}>
                <img src={typeIconDisplay()} alt="dokumentum típus" />
              </Box>
            </div>
          )}
          <div className={elementTemplateStyles.osszefoglalasTextContainer}>
            <Grid container className={'docDataImportant'} direction="column">
              <h4 className={'value'}>
                {get901bValue() || 'Nem ismert típus.'}
              </h4>
              {dataList.map(documentValue => (
                <div className={'line'} key={documentValue.name}>
                  <div className={'label'}>
                    <b>{documentValue.name}:</b>
                  </div>
                  <div className={'value'}>
                    {!documentValue.value || documentValue.value.length === 0
                      ? 'Nem ismert'
                      : parseArray(documentValue.value)}
                  </div>
                </div>
              ))}
            </Grid>
          </div>
        </div>
        <Box className={elementTemplateStyles.boxDataContainer}></Box>
        <Box className={'docDataMoreHeadline'}>
          <span>Részletek</span>
        </Box>
        <Box className={'docDataFull'}>
          {documentDetailsValues.map(documentValue => {
            let documentValueDisplay = null;
            if (!documentValue.value || documentValue.value.length !== 0) {
              if (documentValue.value !== null) {
                documentValueDisplay = (
                  <div className={'line'} key={documentValue.name}>
                    <div className={'label'}>{documentValue.name}:</div>
                    <div className={'value'}>
                      {parseArray(documentValue.value)}
                    </div>
                  </div>
                );
              }
            }
            return documentValueDisplay;
          })}
        </Box>
        <Grid
          className={styles.mainContainer + ' dataListContainer'}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Box
            className={styles.pageContentContainerSubpageWhite}
            component="section"
          >
            <Grid container>
              <Grid
                item
                className={searchStyles.searchResultsList}
                style={{ width: '100%', padding: 0 }}
              >
                <Box className={searchStyles.searchResultsItems}>
                  <ol>
                    <div className={'elementTemplateTables'}>
                      {// lehet ide kelleni fog majd új feature control változó
                      controlledFeatures.itemList && (
                        <div
                          className={searchStyles.instanceData}
                          style={{ marginBottom: '10px' }}
                        >
                          <DocumentsTable
                            documents={documents}
                            count={documents.length}
                          />
                        </div>
                      )}
                      {controlledFeatures.itemList && (
                        <div className={searchStyles.instanceData}>
                          <ItemsTable
                            bibId={guid}
                            bib={bibliographicRecord}
                            me={meData?.me}
                            setReservationData={setReservationData}
                            setRequestData={setRequestData}
                            count={physicalManifestations?.aggregate?.count}
                          />
                        </div>
                      )}
                      <RecordConnectionController
                        rawMarcRecord={rawMarcRecord}
                        parentGuids={[]}
                      >
                        {({ uniqConnectedBibIds, parentGuids }) =>
                          connectedRecordTypeBasedOnPeriodicLevel.map(x => (
                            <div
                              className={searchStyles.instanceData}
                              style={{ marginTop: '10px' }}
                            >
                              <ConnectedRecords
                                connectedBibIds={uniqConnectedBibIds}
                                parentGuids={[...parentGuids, guid]}
                                periodicalLevels={x.levels}
                                name={x.name}
                                periodicalType={x.type}
                              />
                            </div>
                          ))
                        }
                      </RecordConnectionController>
                    </div>
                  </ol>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {controlledFeatures.itemList && <Grid container justifyContent="center">
          <Box className={searchStyles.paginationBoxInner}>
            <Box className={searchStyles.paginationBoxInnerContent}>
              <Pagination
                offset={skip}
                limit={first}
                total={physicalManifestations?.aggregate?.count}
                onClick={(_, offset) => {
                  fetchMore({
                    variables: {
                      skip: offset,
                    },
                    updateQuery(prev, { fetchMoreResult }) {
                      if (!fetchMoreResult) return prev;
                      setSkip(offset);

                      return fetchMoreResult;
                    },
                  });
                }}
              />
            </Box>
          </Box>
        </Grid>}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <SubPageLayout>
        <Head title={pageTitle} />
        <BreadcrumbsLayout
          menuItems={[
            { name: 'Főoldal', path: '/' },
            { name: 'Keresési találatok', path: '/record_list' },
            { name: pageTitle },
          ]}
        >
          <div className={styles.textContentContainer}>
            <h3>Adatlap</h3>
            <div className={'dataBox'}>
              {pageDisplay}
              <Box className={'docDataBackLink'}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    {fromElements ? (
                      <Button
                        variant="contained"
                        className={'backButton'}
                        onClick={() => window.history.back()}
                        // onClick={() => navigate('/element_list?keyword=')}
                      >
                        Vissza a találati listára
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className={'backButton'}
                        onClick={() => navigate('/')}
                      >
                        Vissza a főoldalra
                      </Button>
                    )}
                  </Grid>
                  {!accessToken || !data ? null : (
                    <Grid item>
                      {data.bibliographicRecord.hasActiveRequest ? (
                        <Button
                          disabled
                          variant="contained"
                          size="large"
                          style={{ marginTop: '18px' }}
                          // className={'backButton'}
                        >
                          Előjegyezve
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          className={'backButton'}
                          onClick={handleBibRequest}
                          disabled={mutationLoading}
                        >
                          Előjegyzés
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </div>
          </div>
        </BreadcrumbsLayout>
      </SubPageLayout>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={errorModal.open}
        autoHideDuration={6000}
        onClose={closeErrorModalHandler}
      >
        <ErrorSnackbar
          onClose={closeErrorModalHandler}
          variant="error"
          message={errorModal.errorMessage}
        />
      </Snackbar>
      <ViewMyReservationModal
        reservationData={reservationData}
        handleClose={() => setReservationData(null)}
      />
      <ViewMyRequestModal
        requestData={requestData}
        handleClose={() => setRequestData(null)}
      />
    </React.Fragment>
  );
};

export default ElementTemplate;
